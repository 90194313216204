import { useState } from "react";
import { IconButtonAnimate } from "../../../components/_MUI/animate";
import Iconify from "../../../components/_MUI/Iconify";
import MenuPopover from "../../../components/_MUI/MenuPopover";
import { Link, MenuItem, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function ({

}) {
    const { t } = useTranslation(TranslatorNS.SETTINGS);
    
    const [open, setOpen] = useState<HTMLElement | null>(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setOpen(event.currentTarget);
    };
  
    const handleClose = () => {
      setOpen(null);
    };

    return (
        <>
            <Tooltip title={t("topbar.helpTooltip")}>
                <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {bgcolor: "action.selected" }),
                }}
                >
                        <Iconify icon="uil:question-circle" sx={{ color: "grey.300" }}/>
                </IconButtonAnimate>
            </Tooltip>
                <MenuPopover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleClose}
                    sx={{
                        mt: 0.5,
                        ml: 0.75,
                    }}
                >
                    <Stack>
                        <MenuItem
                            onClick={handleClose}
                            disableRipple
                            component={Link}
                            target="_blank"
                            href="http://help.zenfitapp.com/en/collections/2805934-trainers-faq">        
                            {t("topbar.supportArticles")}
                        </MenuItem>
                        <MenuItem
                            onClick={handleClose}
                            disableRipple
                            component={Link}
                            target="_blank"
                            href="https://zenfit.sleekplan.app/">
                            {t("topbar.requestFeature")}
                        </MenuItem>
                    </Stack>
            </MenuPopover>
        </>
    );
}